<template>
  <!--
    The view for the Panocam Media
  -->
  <Portlet
    :title="$t('image')"
    icon="camera"
    class="panocamMedia"
  >
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="panocamMedia != null && panocamMedia.image != null">
        <p v-if="panocamMedia.image.timestampIso">
          Last Panocam Image taken at: {{ dateTime_dateTime(panocamMedia.image.timestampIso) }}
        </p>
        <img
          v-if="panocamMedia.image.sizes"
          id="img"
          :src="getDefaultResolution()"
          class="img-fluid"
        >
      </template>
      <template v-else>
        {{ $t('noDataAvailable') }}
      </template>
    </template>
  </Portlet>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
export default {
  name: "InstallationDetailPanocamImage",
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      installationObject: null,
      panocamMedia: null
    }
  },
  metaInfo () {
    return {
      title: 'Panocam'
    }
  },
  created () {
    this.getInstallation();
  },
  methods: {
    getDefaultResolution () {
      var defaultItem = this.panocamMedia.image.sizes.find(x => x.resolution == "default");
      if(defaultItem) {
        return defaultItem.url;
      }
      return "";
    },
    getInstallation () {
      this.loading = true;
      this.axios.get('/Installation/Get?id=' + this.installationId)
        .then((response) => {
          this.installationObject = response.data;
          if(this.installationObject && this.installationObject.spotId1) {
            this.getMedia(this.installationObject.spotId1);
          }
          else {
            this.loading = false;
          }
        })
    },
    getMedia (spotId) {
      this.loading = true;
      this.axios.get('/Media/GetPanocamMedia?id=' + spotId)
        .then((response) => {
          this.panocamMedia = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

}
</script>